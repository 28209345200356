import { useEffect, useState } from "react";

import { ButtonSmall } from "@anwb/poncho/components/button";
import Form, { FormProps } from "@anwb/poncho/components/form";
import FormBlock from "@anwb/poncho/components/form-block";
import FormContainerFieldSet from "@anwb/poncho/components/form-container-field-set";
import FormFieldButton from "@anwb/poncho/components/form-field-button";
import Grid from "@anwb/poncho/components/grid";
import Link from "@anwb/poncho/components/link";
import Notification from "@anwb/poncho/components/notification";
import Table from "@anwb/poncho/components/table";
import { Tag } from "@anwb/poncho/components/tags";
import Typography from "@anwb/poncho/components/typography";

import { useAuthorizedRequest } from "../../helpers/api";
import { useStore } from "../../store/useStore";
import { DemoUser } from "../../types/demoUser";
import FormField from "../FormField/FormField";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const demoUserFields = ["email", "firstname", "lastname"] as const;
type ValidProps = (typeof demoUserFields)[number];
type DemoUsersParams = Record<ValidProps, string>;

const defaultDemoUserValues: DemoUsersParams = demoUserFields.reduce((r, key): DemoUsersParams => {
  return {
    ...r,
    [key]: "",
  };
}, {} as DemoUsersParams);

function DemoUsers() {
  const [demoUsers, setDemoUsers] = useState<DemoUser[]>([]);
  const [needsRefresh, setNeedsRefresh] = useState(true);
  const demoUsersState = useState<DemoUsersParams>(defaultDemoUserValues);
  const demoUserValues = demoUsersState[0];
  const { setNotification, moodleBaseURL } = useStore((state) => ({
    setNotification: state.setNotification,
    moodleBaseURL: state.moodleBaseURL,
  }));

  const createDemoUserRequest = useAuthorizedRequest<{ password: string }>();

  const createDemoUser = (formData: HTMLFormElement) => {
    if (!formData.valid) return;

    createDemoUserRequest.setRequest({
      method: "post",
      path: `/demo-users`,
      data: demoUserValues,
      onSuccess: (response) => {
        setNeedsRefresh(true);
        setNotification({
          title: "Demo user is created",
          children: /anwb\.nl/.exec(demoUserValues.email)
            ? null
            : `The password for this user is: ${response.password}`,
          variant: "success",
        });
      },
      onAxiosError: (error) => {
        if (error.response?.status === 409) {
          setNotification({
            title: "Demo user has not been created",
            children: `Demo user with e-mail address ${demoUserValues.email} already exists`,
            variant: "error",
          });
        }
      },
    });
  };

  const deleteDemoUserRequest = useAuthorizedRequest();
  const deleteDemoUser = (demoUserId: number) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete demo user #${demoUserId}?`)) {
      deleteDemoUserRequest.setRequest({
        method: "delete",
        path: `/demo-users/${demoUserId}`,
        onSuccess: () => {
          setNotification({
            title: "Demo user is deleted",
            variant: "success",
          });
          setNeedsRefresh(true);
        },
      });
    }
  };

  const { isLoading, setRequest } = useAuthorizedRequest<DemoUser[]>();
  useEffect(() => {
    if (needsRefresh) {
      setRequest({
        path: `/demo-users`,
        onSuccess: (data) => {
          setDemoUsers(data);
        },
      });
      setNeedsRefresh(false);
    }
  }, [setRequest, needsRefresh, setNeedsRefresh]);

  return (
    <Grid columns={1}>
      <Grid.Item>
        <Form onSubmit={createDemoUser as FormProps["onSubmit"]}>
          <FormBlock>
            <FormContainerFieldSet>
              <FormField required useState={demoUsersState} keyword="email" label="Email" />
            </FormContainerFieldSet>
            <FormContainerFieldSet>
              <FormField
                required
                useState={demoUsersState}
                keyword="firstname"
                label="First name"
              />
              <FormField required useState={demoUsersState} keyword="lastname" label="Last name" />
            </FormContainerFieldSet>

            <FormFieldButton type="submit">Create demo user</FormFieldButton>
          </FormBlock>
        </Form>
      </Grid.Item>
      <Grid.Item>
        <Notification variant="info" title="Azure AD">
          For ANWB employees to be able to log in, they also need to be added to the Azure AD group{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://account.activedirectory.windowsazure.com/r#/manageMembership?objectType=Group&objectId=dcfbcfae-68d0-4deb-8d93-72bb992ae043"
          >
            anwb.app.streetwise.prod.users
          </a>
          . Request access via <a href="mailto:kdeboer@anwb.nl">Kyra de Boer</a> (or{" "}
          <a href="mailto:itverkeerseducatie@anwb.nl">itverkeerseducatie@anwb.nl</a>).
        </Notification>
        <Notification variant="info" title="Documentation">
          <a
            href="https://anwb.atlassian.net/wiki/spaces/LMSA/pages/40502346/Demo+gebruikers"
            rel="noreferrer"
            target="_blank"
          >
            See for more details the documentation on Confluence
          </a>
          .
        </Notification>
      </Grid.Item>
      <Grid.Item>
        <Typography variant="number">{demoUsers.length} demo users found</Typography>
      </Grid.Item>
      <Grid.Item>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderColumn>Moodle ID</Table.HeaderColumn>
                <Table.HeaderColumn>Email</Table.HeaderColumn>
                <Table.HeaderColumn>First name</Table.HeaderColumn>
                <Table.HeaderColumn>Last name</Table.HeaderColumn>
                <Table.HeaderColumn>Account Type</Table.HeaderColumn>
                <Table.HeaderColumn>Actions</Table.HeaderColumn>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {demoUsers?.map((demoUser) => {
                return (
                  <Table.Row key={demoUser.id}>
                    <Table.Column>
                      {(moodleBaseURL !== undefined && (
                        <Link
                          href={`${moodleBaseURL}/user/profile.php?id=${demoUser.id}`}
                          iconInverted={false}
                        >
                          {demoUser.id}
                        </Link>
                      )) ||
                        demoUser.id}
                    </Table.Column>
                    <Table.Column>{demoUser.email}</Table.Column>
                    <Table.Column>{demoUser.firstname}</Table.Column>
                    <Table.Column>{demoUser.lastname}</Table.Column>
                    <Table.Column>
                      <Tag>
                        <Tag.Icon
                          type="illustrative"
                          variant={demoUser.auth === "oauth2" ? "work" : "house"}
                        />
                        <Tag.Label>
                          {demoUser.auth === "oauth2" ? "ANWB employee" : "External"}
                        </Tag.Label>
                      </Tag>
                    </Table.Column>
                    <Table.Column>
                      <ButtonSmall color="primary" onClick={() => deleteDemoUser(demoUser.id)}>
                        Delete
                      </ButtonSmall>
                    </Table.Column>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </Grid.Item>
    </Grid>
  );
}

export default DemoUsers;
