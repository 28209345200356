import { Tooltip } from "@anwb/poncho/components/tooltip";

export function BrinvestToolTip() {
  return (
    <Tooltip>
      A Brinvest (in Dutch <em>BRIN vestigingsnummer</em>) is a unique six-digit code used to
      identify individual school locations. For more information, visit{" "}
      <a
        href="https://nl.wikipedia.org/wiki/Basisregistratie_Instellingen"
        rel="noreferrer"
        target="_blank"
      >
        Basisregistratie Instellingen
      </a>{" "}
      (Dutch).
    </Tooltip>
  );
}
