import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";

if (process.env.REACT_APP_MSAL_CLIENT_ID === undefined) {
  throw new Error("MSAL client id is missing");
}

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: "https://login.microsoftonline.com/7392aafd-1b2a-46f7-81a9-ab0e2f0e2146",
    knownAuthorities: ["login.microsoftonline.com"],
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};

export default msalConfig;
