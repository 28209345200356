export enum Jaargroep {
  Jaar0 = "0",
  Jaar1 = "1",
  Jaar2 = "2",
  Jaar3 = "3",
  Jaar4 = "4",
  Jaar5 = "5",
  Jaar6 = "6",
  Jaar7 = "7",
  Jaar8 = "8",
  Speciaal = "S",
  Voortgezet = "V",
}
