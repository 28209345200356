import { AxiosResponse } from "axios";

import { Product } from "./product";
import { School } from "./school";

export enum Status {
  Actief = "Actief",
  Definitief = "Definitief",
  Foutief = "Foutief",
  Verlopen = "Verlopen",
  Nieuw = "Nieuw",
  Geannuleerd = "Geannuleerd",
}

export type Order = {
  id: string;
  endDate: string;
  product: Product;
  salesforceLastModifiedDate: string;
  school: School;
  startDate: string;
  status: Status;
  errorMessage?: string | null;
};

export interface GetOrdersResponse extends AxiosResponse {
  data: {
    orders: Order[];
  };
}
