import { FilterConfiguration, StoreFilters } from "../types/filters";

export default class FilterQueryBuilder {
  filters: StoreFilters;

  filtersForCurrentView: FilterConfiguration[];

  constructor(filters: StoreFilters, filtersForCurrentView: FilterConfiguration[]) {
    this.filters = filters;
    this.filtersForCurrentView = filtersForCurrentView;
  }

  getUrlSearchParamString() {
    const queryItems = this.getQueryItems();
    if (Object.keys(queryItems).length === 0) {
      return "";
    }
    return `?${new URLSearchParams(queryItems).toString()}`;
  }

  getQueryItems() {
    const queryItems: { [key: string]: string } = {};

    this.filtersForCurrentView.forEach((filter) => {
      if (this.filters[filter.keyword] !== "none") {
        queryItems[filter.keyword] = this.filters[filter.keyword];
      }
    });
    return queryItems;
  }

  getFilterCount() {
    return Object.keys(this.getQueryItems()).length;
  }
}
