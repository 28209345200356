import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { formatDistance } from "date-fns";
import { enGB } from "date-fns/locale";

import { ButtonSmall } from "@anwb/poncho/components/button";
import Pagination from "@anwb/poncho/components/pagination";
import Table from "@anwb/poncho/components/table";
import { Tooltip } from "@anwb/poncho/components/tooltip";
import Typography from "@anwb/poncho/components/typography";

import { useAuthorizedRequest } from "../../../../helpers/api";
import { useStore } from "../../../../store/useStore";
import { School } from "../../../../types/school";
import { BrinvestToolTip } from "../../../BrinvestToolTip/BrinvestToolTip";
import NoWrap from "../../../NoWrap/NoWrap";

type SchoolTableProps = {
  schools: School[];
};

const SCHOOLS_PER_PAGE = 25;

function SchoolTable({ schools }: SchoolTableProps) {
  const { setNotification, salesforceBaseURL, moodleBaseURL, setFilters, filters } = useStore(
    (state) => ({
      setNotification: state.setNotification,
      salesforceBaseURL: state.salesforceBaseURL,
      moodleBaseURL: state.moodleBaseURL,
      setFilters: state.setFilters,
      filters: state.filters,
    }),
  );
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [usePagination, setUsePagination] = useState(true);

  useEffect(() => {
    setCurrentPage(1);
  }, [schools]);

  const cleanUpRequest = useAuthorizedRequest();
  const cleanUpSchool = (brinvest: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete all users in Moodle for this school?")) {
      cleanUpRequest.setRequest({
        method: "post",
        path: `/schools/${brinvest}/clean-up`,
        onSuccess: () => {
          setNotification({
            title: "School cleaned up",
            variant: "success",
          });
        },
      });
    }
  };

  const createMoodleTenantRequest = useAuthorizedRequest();
  const createMoodleTenant = (brinvest: string) => {
    createMoodleTenantRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/create-tenant`,
      onSuccess: () => {
        setNotification({
          title: "Moodle Tenant created",
          variant: "success",
        });
      },
    });
  };

  const matchBasispoortInstellingRequest = useAuthorizedRequest<{ status: string }>();
  const matchBasispoortInstelling = (brinvest: string) => {
    matchBasispoortInstellingRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/match-basispoort-instelling`,
      onSuccess: (data) => {
        if (data.status === "error") {
          setNotification({
            title: `Basispoort Instelling to match was not found.`,
            variant: "warning",
          });
        } else {
          setNotification({
            title: `Basispoort Instelling is matched.`,
            variant: "success",
          });
        }
      },
    });
  };

  const synchronizeSchoolRequest = useAuthorizedRequest();
  const synchronizeSchool = (brinvest: string) => {
    synchronizeSchoolRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/synchronize`,
      onSuccess: () => {
        setNotification({
          title: `School has been synchronized.`,
          variant: "success",
        });
      },
    });
  };

  const allocateUsersOnSchoolToTenantRequest = useAuthorizedRequest();
  const allocateUsersOnSchoolToTenant = (brinvest: string) => {
    allocateUsersOnSchoolToTenantRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/allocate-users-to-tenant`,
      onSuccess: () => {
        setNotification({
          title: `Users on school will be allocated to its tenant.`,
          variant: "success",
        });
      },
    });
  };

  const listUsers = (brinvest: string) => {
    setFilters({ ...filters, ...{ brinvest } });
    navigate(`/users`);
  };

  const schoolCount = schools.length;
  const firstSchoolIndex = usePagination ? (currentPage - 1) * SCHOOLS_PER_PAGE : 0;
  const lastSchoolIndex = usePagination
    ? Math.min(schoolCount, currentPage * SCHOOLS_PER_PAGE)
    : schoolCount;

  return (
    <>
      <Typography variant="number">
        Found {schoolCount} school{schoolCount !== 1 ? "s" : ""}
        {schoolCount > 0 && (
          <>
            , showing {firstSchoolIndex + 1} to {lastSchoolIndex}
            {schoolCount > SCHOOLS_PER_PAGE && (
              <>
                &nbsp;
                <ButtonSmall color="tertiary" onClick={() => setUsePagination(!usePagination)}>
                  ({usePagination ? "Show all" : `Show ${SCHOOLS_PER_PAGE}`})
                </ButtonSmall>
              </>
            )}
          </>
        )}
      </Typography>
      <Table textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderColumn>
              Brinvest
              <BrinvestToolTip />
            </Table.HeaderColumn>
            <Table.HeaderColumn>Basispoort ID</Table.HeaderColumn>
            <Table.HeaderColumn>Last Synced</Table.HeaderColumn>
            <Table.HeaderColumn>ICT Coordinator Last Notified</Table.HeaderColumn>
            <Table.HeaderColumn>Moodle tenant ID</Table.HeaderColumn>
            <Table.HeaderColumn>Details</Table.HeaderColumn>
            <Table.HeaderColumn>Actions</Table.HeaderColumn>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {schools.slice(firstSchoolIndex, lastSchoolIndex).map((school) => {
            return (
              <Table.Row key={school.brinvest}>
                <Table.Column>{school.brinvest}</Table.Column>
                <Table.Column>
                  {school.basispoortId && (
                    <ButtonSmall
                      color="tertiary"
                      onClick={() => {
                        if (school.basispoortId !== null) {
                          navigate(`/schools/basispoort-info/${school.basispoortId}`);
                        }
                      }}
                    >
                      {school.basispoortId}
                    </ButtonSmall>
                  )}
                </Table.Column>
                <Table.Column title={school.lastSyncedDate ?? ""}>
                  {school.lastSyncedDate &&
                    formatDistance(new Date(school.lastSyncedDate), new Date(), {
                      locale: enGB,
                      addSuffix: true,
                    })}
                </Table.Column>
                <Table.Column title={school.ictCoordinatorLastNotifiedDate ?? ""}>
                  {school.ictCoordinatorLastNotifiedDate &&
                    formatDistance(new Date(school.ictCoordinatorLastNotifiedDate), new Date(), {
                      locale: enGB,
                      addSuffix: true,
                    })}
                </Table.Column>
                <Table.Column>
                  {school.moodleTenantId && moodleBaseURL && (
                    <ButtonSmall
                      color="tertiary"
                      href={`${moodleBaseURL}/admin/tool/tenant/edit.php?id=${school.moodleTenantId}`}
                    >
                      {school.moodleTenantId}
                    </ButtonSmall>
                  )}
                </Table.Column>
                <Table.Column>
                  {salesforceBaseURL && (
                    <NoWrap>
                      <ButtonSmall
                        color="tertiary"
                        href={`${salesforceBaseURL}/lightning/r/Account/${school.salesforceId}/view`}
                      >
                        Salesforce
                      </ButtonSmall>
                    </NoWrap>
                  )}
                  {school.basispoortId && (
                    <NoWrap>
                      <ButtonSmall
                        color="tertiary"
                        onClick={() => navigate(`/schools/${school.brinvest}/instelling`)}
                      >
                        Basispoort
                      </ButtonSmall>
                    </NoWrap>
                  )}
                  <NoWrap>
                    <ButtonSmall
                      onClick={() => navigate(`/orders/${school.brinvest}`)}
                      color="tertiary"
                    >
                      Orders
                    </ButtonSmall>
                  </NoWrap>
                  <NoWrap>
                    <ButtonSmall onClick={() => listUsers(school.brinvest)} color="tertiary">
                      Users
                    </ButtonSmall>
                  </NoWrap>
                  <NoWrap>
                    <ButtonSmall
                      onClick={() => navigate(`/program_group/${school.brinvest}`)}
                      color="tertiary"
                    >
                      Programs
                    </ButtonSmall>
                  </NoWrap>
                </Table.Column>
                <Table.Column>
                  {!school.basispoortId && (
                    <NoWrap>
                      <ButtonSmall
                        color="primary"
                        onClick={() => matchBasispoortInstelling(school.brinvest)}
                      >
                        Match Basispoort Instelling
                      </ButtonSmall>
                      <Tooltip>
                        Retrieves the instelling from Basispoort using the school&apos;s
                        <em>brinvest</em> code. If found, the Basispoort ID is added to the school
                        record; otherwise, an error is returned.
                      </Tooltip>
                    </NoWrap>
                  )}
                  {school.moodleTenantId === null && (
                    <NoWrap>
                      <ButtonSmall
                        color="primary"
                        onClick={() => createMoodleTenant(school.brinvest)}
                      >
                        Create tenant
                      </ButtonSmall>
                      <Tooltip>
                        Creates a new Moodle Tenant for the school, to which the teachers and
                        students can be added.
                      </Tooltip>
                    </NoWrap>
                  )}
                  <NoWrap>
                    <ButtonSmall color="primary" onClick={() => cleanUpSchool(school.brinvest)}>
                      Clean up
                    </ButtonSmall>
                    <Tooltip>
                      Removes all users from Moodle and revokes the permissions for those users in
                      Basispoort.
                    </Tooltip>
                  </NoWrap>
                  <NoWrap>
                    <ButtonSmall color="primary" onClick={() => synchronizeSchool(school.brinvest)}>
                      Synchronize
                    </ButtonSmall>
                    <Tooltip>
                      Synchronizes Basispoort with License Office, triggering a sync with Moodle and
                      (re)setting access permissions for Streetwise and Beheermodule in Basispoort.
                    </Tooltip>
                  </NoWrap>
                  <NoWrap>
                    <ButtonSmall
                      color="primary"
                      onClick={() => allocateUsersOnSchoolToTenant(school.brinvest)}
                    >
                      Add to tenant
                    </ButtonSmall>
                    <Tooltip>
                      Assigns all users from this school to the school&apos;s Moodle tenant. This
                      process may take several minutes.
                    </Tooltip>
                  </NoWrap>
                </Table.Column>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {usePagination && schoolCount > SCHOOLS_PER_PAGE && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(schoolCount / SCHOOLS_PER_PAGE)}
          onPaginationChange={(number) => setCurrentPage(number)}
        />
      )}
    </>
  );
}

export default SchoolTable;
