import { SavedRecord, Schema } from "@jsforce/jsforce-node";
import { SObjectDefinition } from "@jsforce/jsforce-node/lib/types/schema";

interface ActiviteitDefinition extends SObjectDefinition {
  Name: "Activiteit__c";
  Fields: ActiviteitRecord;
  ParentReferences: {
    Account: AccountDefinition;
  };
  ChildRelationships: {};
}

interface AccountDefinition extends SObjectDefinition {
  Name: "Account";
  Fields: AccountRecord;
  ParentReferences: {};
  ChildRelationships: {
    Activiteit: ActiviteitDefinition;
  };
}

export interface LicentiekantoorSchema extends Schema {
  SObjects: {
    Activiteit__c: ActiviteitDefinition;
    Account: AccountDefinition;
  };
}

export type SalesforceId = string;

export enum Status {
  Nieuw = "Nieuw",
  Definitief = "Definitief",
  Actief = "Actief",
  Verlopen = "Verlopen",
  Geannuleerd = "Geannuleerd",
  Foutief = "Foutief",
}

export enum ProductCode {
  Theorie = "Theorie",
  Praktijk = "Praktijk",
}
// TODO als geannuleerd en of opnieuw naar nieuw ook de licenties annuleren (na overleg met Robert Hoornweg)

export interface ActiviteitRecord extends SavedRecord {
  LastModifiedDate: string;
  School_f__c: string;
  LMS_Status__c: Status;
  LMS_Product__c: ProductCode;
  LMS_Foutmelding__c: string;
  Vestigingsnummer__c: string;
  Schooljaar__c: string;
  Column0_studentCount__c: number;
  Column1_studentCount__c: number;
  Column2_studentCount__c: number;
  Column3_studentCount__c: number;
  Column4_studentCount__c: number;
  Column5_studentCount__c: number;
  Column6_studentCount__c: number;
  Column7_studentCount__c: number;
  Column8_studentCount__c: number;
  ColumnS_studentCount__c: number;
  ColumnV_studentCount__c: number;
  IsDeleted: boolean;
}

export interface AccountRecord extends SavedRecord {
  Geldig_VerwerkersOvk__c: boolean;
}

export type SalesforceSecrets = {
  instanceUrl: string;
  username: string;
  password: string;
  securityToken: string;
};
