export default function enumToOptions<E extends string>(e: Record<string, E>): Record<E, E> {
  const values = Object.values(e);

  return values.reduce(
    (accumulator: Record<E, E>, current: E) => {
      accumulator[current] = current;
      return accumulator;
    },
    {} as Record<E, E>,
  );
}
