import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Grid from "@anwb/poncho/components/grid";
import Notification from "@anwb/poncho/components/notification";
import Table from "@anwb/poncho/components/table";
import Typography from "@anwb/poncho/components/typography";

import { assertIsDefined } from "@licentiekantoor/shared/src/util/assertIsDefined";

import { useAuthorizedRequest } from "../../helpers/api";
import { Program } from "../../types/program";
import BooleanIcon from "../BooleanIcon/BooleanIcon";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

type ProgramGroupForSchoolProps = {
  brinvest: string;
};

interface Group {
  id: string;
  naam: string;
}

interface ProgramGroups {
  id: string;
  status: boolean;
  group: string;
  program: string;
}

function doesGroupHaveProgram(groupId: string, programId: string, programGroups: ProgramGroups[]) {
  return programGroups.some(
    (programGroup) =>
      programGroup.group === groupId && programGroup.program === programId && programGroup.status,
  );
}

function ProgramGroupForSchool() {
  const { brinvest } = useParams<ProgramGroupForSchoolProps>();
  assertIsDefined(brinvest, "Unknown BRINVEST");

  const [programs, setPrograms] = useState<Program[]>([]);
  const programsRequest = useAuthorizedRequest<Program[]>();

  const [groups, setGroups] = useState<Group[]>([]);
  const groupsRequest = useAuthorizedRequest<Group[]>();

  const [programGroups, setProgramGroups] = useState<ProgramGroups[]>([]);
  const programGroupsRequest = useAuthorizedRequest<ProgramGroups[]>();

  const setProgramsRequest = programsRequest.setRequest;
  useEffect(() => {
    setProgramsRequest({
      path: `/programs?manageable=true`,
      onSuccess: (data) => {
        data.sort((a, b) => a.name.localeCompare(b.name));
        setPrograms(data);
      },
    });
  }, [setProgramsRequest]);

  const setGroupsRequest = groupsRequest.setRequest;
  useEffect(() => {
    setGroupsRequest({
      path: `/groups?brinvest=${brinvest}`,
      onSuccess: (data) => {
        data.sort((a, b) => a.naam.localeCompare(b.naam));
        setGroups(data);
      },
    });
  }, [setGroupsRequest, brinvest]);

  const setProgramGroupsRequest = programGroupsRequest.setRequest;
  useEffect(() => {
    setProgramGroupsRequest({
      path: `/schools/${brinvest}/programs-groups`,
      onSuccess: (data) => {
        setProgramGroups(data);
      },
    });
  }, [setProgramGroupsRequest, brinvest]);

  const isLoading = groupsRequest.isLoading || programsRequest.isLoading;

  return (
    <Grid columns={1}>
      <Grid.Item>
        <Typography variant="content-title">Programs per group for school: {brinvest}</Typography>
      </Grid.Item>
      <Grid.Item>
        <Notification variant="info" title="Beheermodule">
          This section shows the programs that teachers at this school have selected for their
          groups in the <em>Beheermodule</em>. If a group has no checkmarks, it means it has no
          assigned program, and its students will not have access to Moodle.
        </Notification>
        <Notification variant="warning" title="Active license required">
          If a school does not have an active license for <em>Streetwise Digitaal</em>, its students
          will not be able to access the selected programs.
        </Notification>
      </Grid.Item>
      <Grid.Item>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderColumn>Program</Table.HeaderColumn>
                {programs.map((program) => (
                  <Table.HeaderColumn key={program.id}>{program.name}</Table.HeaderColumn>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {groups.map((group) => (
                <Table.Row key={group.id}>
                  <Table.Column>{group.naam}</Table.Column>
                  {programs.map((program) => {
                    return (
                      <Table.Column key={program.id}>
                        <BooleanIcon
                          isTrue={doesGroupHaveProgram(group.id, program.id, programGroups)}
                        />
                      </Table.Column>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Grid.Item>
    </Grid>
  );
}

export default ProgramGroupForSchool;
